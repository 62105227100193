@import 'mixins';


::selection{
    background-color: $orange;
    color: white;
}

::-webkit-scrollbar{
    // display: none;
}
*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

h2{
    width: calc(100% - 10px);
    padding: 0px 5px 0px 5px;
    @include flexColumn(flex-start);
    align-items: center;
    font-weight: 400;
    // letter-spacing: ;
    font-size: 30px;
    color: black;
    text-align: center;
}

.order-button{
    cursor: pointer;
}

.error{
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
}

.site-header{
    width: 100%;
    height: 60px;
    // background-color: red;
    background-color: white;
    @include flexRow(center);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.363);
    // position: fixed;


    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexRow(space-between);
    }

    &__logo-block{
        min-width: 200px;
        max-width: 250px;
        height: 100%;
        // background-color: pink;
        @include flexRow(flex-start);
        text-decoration: none;
    }

    &__logo-container{
        width: 60px;
        height: 100%;
        // background-color: plum;
        @include flexRow(flex-start);
        align-items: center;
        svg{
            path{
                fill: $orange;
            }
        }
    }

    &__logo{
        max-width: 90%;
        max-height: 90%;
    }

    &__city{
        // background-color: plum;
        height: 100%;
        margin-left: 10px;
        @include flexRow(flex-start);
        align-items: center;
        font-size: 20px;
        transition: 0.3s;
        cursor: pointer;
        color: black;
        &:hover{
            color: $orange;
        }
    }

    &__city_active{
        transition: 0.3s;
        color: $orange;
        &:hover{
            color: black;
        }
    }

    &__sign{
        margin-left: 10px;
        transform: rotate(90deg);
        width: 100%;
        transition: 0.15s;
        // background-color: red;
    }

    &__sign_active{
        transition: 0.3s;
        transform: rotate(-90deg);
    }

    &__cities-block{
        background: white;
        position: absolute;
        top: 100px;
        width: 100%;
        left: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        z-index: 100;
        box-shadow: 0px 10px 5px 0px rgb(0 0 0 / 32%);
    // display: none;
    }

    &__mobile-button{
        width: 40px;
        height: 40px;
        // background-color: blue;
        @include flexRow(center);
        align-items: center;
        position: relative;
        *{
            transition: 0.3s;
        }
        path{
            stroke: $orange;
        }
        display: none
    }

    &__mobile-button_active{
        path{
            position: absolute;
            &:nth-child(1){
                transform: rotate(45deg) translate(5px, -5px);
            }
            &:nth-child(2){
                opacity: 0;
            }
            &:nth-child(3){
                transform: rotate(-45deg) translate(-12px, -2px);
                // transform: translate(0px, -28px);

            }
        }
    }

    &__nav{
        @include flexRow(center);
        height: 100%;
        // background-color: pink;
    }

    &__menu{
        // background-color: plum;
        @include flexRow(space-between);
        list-style: none;
        height: 100%;
    }

    &__menu-item{
        margin: 0px 10px 0px 10px;
        height: 100%;
        // background-color: orange;
        @include flexColumn(center);
    }

    &__menu-link{
        text-decoration: none;
        color: black;
        font-size: 20px;
        transition: 0.3s;
        &:hover{
            color: $orange;
        }
    }

    &__phone{
        height: 100%;
        // background-color: pink;
        @include flexRow(flex-start);
        align-items: center;
        font-size: 24px;
        text-decoration: none;
        color: black;
        transition: 0.3s;
        svg{
            width: 30px;
            height: 30px;
            margin-right: 5px;
            transition: 0.3s;
        }
        &:hover{
            color: $orange;
            svg{
                fill: $orange;
            }
        }
    }
}
main{
    // top: 80px;
}

.main-section{
    width: 100%;
    height: 700px;
    // background-color: red;
    @include flexRow(center);
    position: relative;
    background-image: url(./../src/img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    &__cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.349);
    }

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        z-index: 1;
        @include flexColumn(center);
        align-items: center;
    }

    &__slider{
        // width: 100%;
        // height: 100%;
        // background-color: pink;
        @include flexColumn(center);
        align-items: center;
        // margin-top: 200px;
        min-height: 200px;
        text-align: center;
    }

    &__header{
        width: 100%;
        // background-color: plum;
        text-align: center;
        color: white;
        font-size: 46px;
        font-weight: 400;
    }

    &__button{
        width: 200px;
        height: 50px;
        background: $main-gradient;
        border-radius: 5px;
        @include flexColumn(center);
        text-align: center;
        color: white;
        font-size: 20px;
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            background: $main-gradient-rotate;
        }
    }
}

.pluses-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        height: 100%;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__plus{
        // max-width: 300px;
        min-height: 100px;
        // background-color: pink;
        @include flexRow(center);
        align-items: center;
        margin: 20px;
        flex-wrap: wrap;
        text-decoration: none;
    }

    &__svg-container{
        width: 100px;
        height: 100px;
        // background-color: plum;
        border-radius: 100px;
        @include flexRow(center);
        align-items: center;
        box-shadow: 0px 25px 35px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 10%);
    }

    &__content-container{
        width: 220px;
        // height: 100%;
        // background-color: plum;
        margin-left: 10px;
    }

    &__header{
        text-align: center;
        color: black;
        font-weight: 500;
    }
    
    &__text{
        text-align: center;
        color: black;
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
    }
}

.action-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    position: relative;

    &__cover{
        width: 100%;
        height: 73%;
        background-color: rgba(0, 0, 0, 0.034);
        position: absolute;
    }

    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexRow(center);
        z-index: 1;
        flex-wrap: wrap;
    }

    &__content-block{
        max-width: 600px;
        width: 100%;
        // height: 100%;
        // background-color: pink;
        margin: 0px 10px 0px 10px;
        @include flexColumn(flex-start);
        padding: 25px 0px 25px 0px;
    }

    &__header{
        // background-color: plum;
        // text-align: start;
        align-items: flex-start;
        padding: 0px;
        font-size: 36px;
    }

    &__text{
        margin-top: 15px;
        // background-color: plum;
        font-size: 18px;
        font-weight: 300;
    }
    
    &__form-block{
        margin: 0px 10px 0px 10px;
        max-width: 400px;
        width: 100%;
        height: 400px;
        // background-color: pink;
    }

    &__form{
        width: 100%;
        height: 100%;
        // background-color: plum;
        background: $main-gradient;
        @include flexColumn(flex-start);
        align-items: center;
        border-radius: 5px;
    }

    &__form-header{
        color: white;
        text-align: center;
        margin-top: 30px;
        font-weight: 400;
        font-size: 24px;
    }

    &__input{
        outline: none;
        border: none;
        margin-top: 15px;
        width: 250px;
        height: 40px;
        padding: 0px 0px 0px 5px;
        // border-radius: 5px;
        background-color: transparent;
        box-shadow: 0px 2px 0px 0px white;
        color: white;
        font-size: 20px;
        &::placeholder{
            color: white;
            font-size: 20px;
        }
    }

    &__button{
        margin-top: 30px;
        border: none;
        width: 260px;
        height: 50px;
        color: white;
        font-size: 24px;
        background-color: black;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: lighten(black, 50%);
            background-color: orange;
        }
    }
}

.about-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
        // flex-wrap: wrap;
    }

    &__items-container{
        margin-top: 30px;
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: pink;
    }

    &__item{
        max-width: 250px;
        // height: 200px;
        width: 100%;
        // background-color: plum;
        @include flexColumn(flex-start);
        align-items: center;
        margin: 25px;
        text-decoration: none;
    }

    &__svg-container{
        width: 150px;
        height: 150px;
        // background-color: orange;
        box-shadow: 0px 25px 35px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 10%);
        border-radius: 100px;
        @include flexRow(center);
        align-items: center;
    }

    &__text{
        text-align: center;
        margin-top: 30px;
        color: black;
    }
}

.price-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        @include flexColumn(flex-start);
        // background-color: yellow;
    }

    &__header{
        font-size: 36px;
    }

    &__text{
        text-align: center;
        margin-top: 10px;
        font-size: 24px;
        font-weight: 300;
    }

    &__table{
        // background-color: pink;
        margin-top: 30px;
        width: 100%;
        // height: unset;
        border-spacing: 0px;
        border-radius: 5px 5px 0px 0px;
        overflow: hidden;
    }

    tbody{
        width: 100%;
    }
    
    tr{
        height: 40px;
        &:nth-child(even){
            background-color: darken(white, 10%);
        }
    }
    
    th{
        color: white;
    }
    
    &__name{
        background-color: $orange;
        width: 40%;
        font-size: 14px;
    }
    
    
    &__quantity{
        font-size: 14px;
        background-color: $orange;
        width: 10%;
    }
    
    &__price{
        font-size: 14px;
        background-color: $orange;
        width: 13%;
        word-wrap: break-word;
    }
    
    &__action{
        font-size: 14px;
        background-color: $orange;
        width: 7%;
    }
    
    
    td{
        // border-collapse: 0px;
        text-align: center;
        font-size: 12px;
    }

    &__button{
        // width: 150px;
        // height: 50px;
        background: $main-gradient;
        padding: 3px;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        transition: 0.3s;
        &:hover{
            background: $main-gradient-rotate;
        }
    }
}

.info-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        font-size: 33px;
        text-align: center;
    }

    &__text{
        text-align: center;
        margin-top: 15px;
        font-size: 20px;
        font-weight: 300;
    }
}

.why-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);
    background-color: darken(white, 3%);

    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexColumn(flex-start);
    }

    &__header{
        font-weight: 33px;
    }

    &__items-container{
        // background-color: pink;
        @include flexRow(center);
        flex-wrap: wrap;
        margin-top: 30px;
    }

    &__item{
        // max-width: 300px;
        min-height: 100px;
        // background-color: plum;
        @include flexRow(center);
        align-items: center;
        margin: 20px;
        flex-wrap: wrap;
        text-decoration: none;
    }

    &__svg-container{
        width: 100px;
        height: 100px;
        // background-color: plum;
        // background-color: orange;
        border-radius: 100px;
        @include flexRow(center);
        align-items: center;
        box-shadow: 0px 25px 35px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 10%);

        svg{
            max-width: 60%;
            max-height: 60%;
            path{
                fill: $orange;
            }
        }
    }

    &__content-container{
        width: 220px;
        // height: 100%;
        // background-color: plum;
        margin-left: 10px;
    }

    &__item-header{
        text-align: center;
        color: black;
        font-weight: 500;
        font-size: 18px;
    }
    
    &__item-text{
        text-align: center;
        color: black;
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
    }
}

.steps-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        @include flexRow(center);
        flex-wrap: wrap;
        // background-color: yellow;
    }
    
    &__step{
        text-decoration: none;
        max-width: 300px;
        width: 100%;
        margin: 30px;
        // background-color: pink;
        @include flexRow(center);
        align-items: center;
    }

    &__img-block{
        // width: 200px;
        // height: 100px;
        // background-color: plum;
        // @include flexRow(center);
        // align-items: center;
    }

    &__img{
        // max-width: 90%;
        // max-height: 90%;
    }

    &__text{
        text-align: center;
        margin-left: 10px;
        color: black;
    }
}

.contacts-section{
    width: 100%;
    padding: 50px 0px;
    // background-color: red;
    @include flexRow(center);
    
    &__container{
        box-shadow: 0px 0px 5px 0px black;
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__map-block{
        max-width: 600px;
        width: 100%;
        height: 600px;
        // background-color: orchid;
    }

    &__contacts-container{
        max-width: 600px;
        width: 100%;
        height: 600px;
        // background-color: pink;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__header{
        margin-top: 30px;
    }

    &__contact{
        @include flexRow(flex-start);
        // background-color: plum;
        align-items: center;
        width: 310px;
        margin-top: 15px;
        text-decoration: none;
    }

    &__svg-container{
        width: 50px;
        height: 50px;
        // background-color: orange;
        box-shadow: 0px 25px 35px rgb(0 0 0 / 5%), inset 0px 4px 4px rgb(0 0 0 / 10%);
        border-radius: 50px;
        @include flexRow(center);
        align-items: center;

        svg{
            width: 30px;
            height: 30px;
        }
    }

    &__contact-text{
        color: black;
        font-size: 18px;
        margin-left: 10px;
        transition: 0.3s;
        &:hover{
            color: $orange;
        }
    }

    &__form{
        width: 100%;
        height: 300px;
        // background-color: plum;
        // background-color: $orange;
        background: $main-gradient;
        margin-top: 38px;
        @include flexColumn(flex-start);
        align-items: center;
    }

    &__form-header{
        margin-top: 30px;
        color: white;
        text-align: center;
    }

    &__input{
        outline: none;
        border: none;
        margin-top: 15px;
        width: 250px;
        height: 40px;
        padding: 0px 0px 0px 5px;
        // border-radius: 5px;
        background-color: transparent;
        box-shadow: 0px 2px 0px 0px white;
        color: white;
        font-size: 20px;
        &::placeholder{
            color: white;
            font-size: 20px;
        }
    }

    &__button{
        margin-top: 30px;
        border: none;
        width: 260px;
        height: 50px;
        color: white;
        font-size: 24px;
        background-color: black;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        &:hover{
            background-color: lighten(black, 50%);
            background-color: orange;
        }
    }
}

.error-block{
    width: 100%;
    height: 90vh;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    text-align: center;

    &__container{
        @include flexColumn(center);
        // background-color: yellow;
        height: 100%;
    }

    &__text{
        text-align: center;
        font-size: 160px;
        // color: $yellow;
        color: $orange;
    }

    &__message{
        // display: none;
        font-size: 36px;
    }

    &__link{
        color: $orange;
        font-size: 36px;
    }
}

.site-footer{
    width: 100%;
    // padding: 50px 0px;
    // min-height: 50px;
    // background-color: red;
    background-color: darken(white, 5%);
    @include flexRow(center);

    &__container{
        max-width: 1200px;
        width: 100%;
        // background-color: yellow;
        @include flexRow(center);
        flex-wrap: wrap;
    }

    &__link{
        height: 50px;
        padding: 0px 20px 0px 20px;
        // background-color: pink;
        text-decoration: none;
        color: black;
        @include flexColumn(center);
        text-align: center;
        font-size: 18px;
        transition: 0.3s;
        cursor: pointer;
        &:hover{
            background-color: darken(white, 10%);
        }
    }
}

.policy-block{
    background-color: $orange;
    @include flexRow(center);
    display: none;

    &__text{
        max-width: 1200px;
        width: 100%;
        // background-color: red;
        padding: 10px;
    }
}

.cities-block{
    width: calc(100% - 20px);
    height: 600px;
    padding: 10px;
    background-color: white;
    @include flexRow(center);
    align-items: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    position: absolute;
    top: 60px;
    z-index: 3;
    display: none;

    &__button{
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: pink;
        left: calc(100% - 20px);
        bottom: calc(100% - 20px);
        border-radius: 5px;
        @include flexRow(center);
        align-items: center;
        color: $orange;
        font-size: 20px;
        background-color: black;
        cursor: pointer;
    }

    &__city{
        color: black;
        margin: 10px;
        max-width: 200px;
        width: 100%;
        min-height: 50px;
        padding: 5px;
        // background-color: yellow;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.26);
        border-radius: 5px;
        text-decoration: none;
        @include flexColumn(center);
        align-items: center;
        font-size: 20px;
        transition: 0.3s;
        &:hover{
            box-shadow:  0px 0px 5px 0px $orange;
            color: $orange;
        }
    }
}

.modal-window{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);
    position: absolute;
    z-index: 99999;
    @include flexRow(center);
    align-items: center;
    display: none;

    &__form{
        padding: 30px;
        // width: 500px;
        // height: 400px;
        background-color: white;
        border-radius: 5px;
        @include flexColumn(flex-start);
        align-items: center;
        position: relative;
    }

    &__close-button{
        position: absolute;
        // background-color: red;
        width: 20px;
        height: 20px;
        @include flexColumn(center);
        align-items: center;
        left: calc(100% - 25px);
        bottom: calc(100% - 25px);
        cursor: pointer;
        svg{
            transition: 0.3s;
        }
        &:hover{
            svg{
                transform: rotate(90deg);
                fill: $orange;
            }
        }
    }

    &__header{
        font-size: 20px;
        width: 200px;
    }

    &__user-input{
        margin-top: 20px;
        width: 200px;
        height: 30px;
        border: 1px solid $orange;
        border-radius: 5px;
        padding: 0px 0px 0px 5px;
        outline: none;
        font-size: 18px;
        font-weight: 300;
    }

    &__button{
        width: 210px;
        height: 40px;
        background-color: darken($main-dark, 20%);
        margin-top: 15px;
        color: white;
        border: none;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;

        &:hover{
            background-color: $orange
        }
    }

    &__policy-text{
        width: 210px;
        margin-top: 10px;
        font-size: 12px;
        text-align: center;
        color: lightgrey;
    }
}

.up-button{
    width: 80px;
    height: 40px;
    background-color: white;
    @include flexColumn(center);
    box-shadow: 0px 0px 0px 3px $orange;
    align-items: flex-start;
    padding: 0px 0px 0px 10px;
    position: fixed;
    top: calc(95% - 50px);
    left: calc(100% - 80px);
    z-index: 100;
    display: none;

    .modal-button__svg-container{
        width: 25px;
        height: 25px;
        border-radius: 65px;
        @include flexColumn(center);
        align-items: center;
        svg{
            width: 30px;
            height: 30px;
            path{
                fill: black;
            }
        }
    }

    &:hover{
        background-color: $orange;
        svg{
            path{
                fill: black;
            }
        }
    }
}

@keyframes modalCallAnim {
    0%{transform: rotate(0deg);}
    50%{transform: rotate(10deg);}
    100%{transform: rotate(0deg);}
}

.call-button{
    // box-shadow: 0px 0px 0px 5px white;
    width: 80px;
    height: 40px;
    background-color: white;
    @include flexColumn(center);
    box-shadow: 0px 0px 0px 3px $orange;
    align-items: flex-end;
    padding: 0px 10px 0px 0px;
    position: fixed;
    top: calc(95% - 50px);
    right: calc(100% - 80px);
    z-index: 100;
    // display: none;
    
    .modal-button__svg-container{
        width: 30px;
        height: 30px;
        border-radius: 65px;
        @include flexColumn(center);
        align-items: center;
        // background-color: red;
    }
    
    svg{
        animation: modalCallAnim 1s infinite;
        fill: white;
        width: 40px;
        height: 40px;
        path{
            fill: black;
        }
    }

    &:hover{
        background-color: $orange;
        svg{
            path{
                fill: black;
            }
        }
    }
}

@media (max-width: 1200px){

    .site-header{
        position: relative;
        height: unset;
        padding: 0px 0px 10px 0px;

        &__container{
            height: unset;
            @include flexColumn(flex-start);
            align-items: center
        }

        &__mobile-button{
            margin-top: 10px;
            @include flexColumn(center);
            align-items: center;
            order: 3;
        }

        &__nav{
            height: unset;
            background-color: white;
            // background-color: red;
            position: absolute;
            width: 100%;
            @include flexColumn(flex-start);
            align-items: center;
            margin-top: 138px;
            z-index: 3;
            padding: 10px 0px 10px 0px;
            display: none;
        }
        
        &__menu{
            z-index: 4;
            // background-color: yellow;
            @include flexColumn(flex-start);
        }

        &__menu-item{
            margin-top: 10px;
            text-align: center;
        }

        &__phone{
            svg{
                display: none;
            }
        }
    }

    .contacts-section{
        &__map-block{
            height: 200px;
        }
    }
}
